<template>
  <div id="inset">
    <top :name="text" />
    <p class="block" @click="chaxunh">进入南航查询</p>
    <div class="input">
      <span>姓名：</span>
      <input type="text" v-model="xing" placeholder="格式:张三@王五@李四" />
    </div>
    <div class="input">
      <span>票号：</span>
      <input type="text" v-model="piao" placeholder="填写第一个姓名的票号" />
    </div>
    <div class="input">
      <span>座位：</span>
      <input type="text" v-model="zuo" placeholder="格式:34K@32B@36A" />
    </div>
    <div class="input">
      <span>航班下标：</span>
      <input type="text" v-model="xia" placeholder="" />
    </div>
    <div class="input">
      <span>南航账号：</span>
      <input type="text" v-model="admin" placeholder="" />
    </div>
    <div class="input">
      <span>南航密码：</span>
      <input type="text" v-model="pwd" placeholder="" />
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <button @click="xuanzuo">选座</button>
    </div>
  </div>
</template>

<script>
import top from "../components/filltop.vue";

export default {
  components: {
    top,
  },
  data() {
    return {
      text: "南航选座",
      xing: "",
      piao: "",
      zuo: "",
      xia: "",
      admin: "",
      pwd: "",
    };
  },
  methods: {
    chaxunh() {
      this.$router.push({
        name: "chaxun",
      });
    },
    xuanzuo() {
      let arr = [
        this.xing,
        this.piao,
        this.zuo,
        this.xia,
        this.admin,
        this.pwd,
      ];
      if (arr.includes("")) {
        this.$toast.fail("信息不全");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "选座请求中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios({
        url: "https://wx.yaolvxing.com/selection/eyset/inao",
        data:
          "xing=" +
          this.xing +
          "&piao=" +
          this.piao +
          "&zuo=" +
          this.zuo +
          "&xia=" +
          this.xia +
          "&admin=" +
          this.admin +
          "&pwd=" +
          this.pwd,
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
        .then((res) => {
          load.close();
          if (res.data.rsCode != "000000") {
            this.$toast.fail(res.data.rsMessage);
            return false;
          }
          this.$toast.success(res.data.rsMessage);
        })
        .catch((err) => {
          console.log(err, "接口报错");
          load.close();
          this.$toast.fail("接口报错");
        });
    },
  },
};
</script>


<style scoped>
.block {
  display: block;
  padding: 0.2rem;
  /* width: 20VW; */
  margin: 0.2rem;
  font-size: 0.34rem;
  text-align: center;
  background: rgb(1, 42, 58);
  color: white;
}
.input {
  padding: 0.3rem 0 0.3rem 0.2rem;
  font-size: 0.34rem;
  width: 100vw;
  height: auto;
  display: flex;
  flex: 1;
}
.input input {
  width: 80vw;
}
button {
  background: #135f7f;
  color: white;
  width: 5rem;
  letter-spacing: 0.1rem;
  border: none;
  border-radius: 60px;
  font-size: 0.4rem;
  height: 1rem;
  margin-top: 0.4rem;
}
</style>